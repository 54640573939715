import { useState, useLayoutEffect, useEffect } from 'react'

const getMatches = (query) => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches
  }

  return false
}

const useMediaQuery = (query) => {
  const [matched, setMatched] = useState(getMatches(query))
  const [loaded, setLoaded] = useState(true)
  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  const handleChange = () => {
    setMatched(getMatches(query))
  }

  useIsomorphicLayoutEffect(() => {
    if (loaded) {
      setLoaded(false)
    }
  }, [])

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    matchMedia.addEventListener('change', handleChange)
    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [query])

  return loaded ? undefined : matched
}

export default useMediaQuery
